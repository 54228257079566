export interface CategoryModel {
    data: Category[];
}

export class Category {
    id?: string;
    categoryId: string;
    name: string;
    slug?: string;
    description?: string;
    parentId?: string[];
    isActive?: boolean = true;
    priority?: number = 0;
    children?: Category[];
}