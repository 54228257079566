<ng-template #payoutModal let-modal>
    <div class="modal-header">
        <h3 class="mb-1 fw-semibold">{{ label! | translate }}</h3>
        <app-button [class]="'btn btn-close'" [spinner]="false" [id]="'payout_close_btn'" (click)="modal.dismiss('Cross click')">
            <i class="ri-close-line"></i>
        </app-button>
    </div>
    <div class="modal-body">
        <div class="border rounded-3 mb-4">
            <table class="table all-package theme-table no-footer">
                <tbody>
                    <tr *ngIf="payoutData.message">
                        <td class="text-start fw-semibold">{{ 'message' | translate }}</td>
                        <td class="text-start">
                             {{ payoutData.message }}
                        </td>
                    </tr>
                    <tr *ngIf="payoutData.reason">
                        <td class="text-start fw-semibold">{{ 'reason' | translate }}</td>
                        <td class="text-start">
                             {{ payoutData.reason }}
                        </td>
                    </tr>
                    <tr *ngIf="payoutData.user.payment_account">
                        <td class="text-start fw-semibold">{{ 'due_to_seller' | translate }}</td>
                        <td class="text-start">
                            {{ payoutData?.amount | currencySymbol }}
                        </td>
                    </tr>
                    <tr *ngIf="payoutData.user.payment_account">
                        <td class="text-start fw-semibold">{{ 'bank_name' | translate }} </td>
                        <td class="text-start">{{ payoutData?.user?.payment_account?.bank_name }}</td>
                    </tr>
                    <tr *ngIf="payoutData.user.payment_account">
                        <td class="text-start fw-semibold">{{ 'bank_account_name' | translate }} </td>
                        <td class="text-start">{{payoutData?.user?.payment_account?.bank_holder_name}}</td>
                    </tr>
                    <tr *ngIf="payoutData.user.payment_account">
                        <td class="text-start fw-semibold">{{ 'bank_account_number' | translate }} </td>
                        <td class="text-start">{{ payoutData?.user?.payment_account?.bank_account_no }}</td>
                    </tr>
                    <tr *ngIf="payoutData.user.payment_account">
                        <td class="text-start fw-semibold">{{ 'bank_ifsc_code' | translate }}</td>
                        <td class="text-start">{{ payoutData?.user?.payment_account?.ifsc }}</td>
                    </tr>
                    <tr *ngIf="payoutData.user.payment_account">
                        <td class="text-start fw-semibold">{{ 'bank_swift_Code' | translate }} </td>
                        <td class="text-start">{{ payoutData?.user?.payment_account?.swift }}</td>
                    </tr>
                    <tr *ngIf="!payoutData.user.payment_account">
                        <td class="text-start fw-semibold">{{ 'amount' | translate }} </td>
                        <td class="text-start">
                            {{ payoutData?.amount | currencySymbol }}
                        </td>
                    </tr>
                    <tr *ngIf="payoutData?.payment_type">
                        <td class="text-start fw-semibold">{{ 'payment_method' | translate }}</td>
                        <td class="text-start">{{ payoutData?.payment_type | uppercase }}</td>
                    </tr>
                    <tr *ngIf="payoutData?.status">
                        <td class="text-start fw-semibold">{{ 'status' | translate }}</td>
                        <td class="text-start">
                            <div class="status-{{ payoutData?.status }}"><span>{{ payoutData?.status | uppercase }}</span></div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div>
            <div class="button-box" *ngIf="payoutData?.status === 'pending'"> 
                <app-button 
                    [class]="'btn-md fw-bold btn btn-secondary'" 
                    [id]="'withdrawal_rejected_btn'"
                    (click)="actionPerform('rejected')">
                    {{ 'rejected' | translate }}
                </app-button>
                <app-button [class]="'btn-theme btn-md fw-bold btn'" 
                    [id]="'withdrawal_approved_btn'"
                    (click)="actionPerform('approved')">
                    {{ 'approved' | translate }}
                </app-button>
            </div>
        </div>
    </div>
</ng-template>