import { CartService } from '@/app/shared/services/cart.service';
import { CommonService } from '@/app/shared/services/common.service';
import { Component, Input, ViewChild } from '@angular/core';
// import { Select, Store } from '@ngxs/store';
// import { Product } from '../../../interface/product.interface';
// import { Cart, CartAddOrUpdate } from '../../../interface/cart.interface';
// import { AddToCart } from '../../../action/cart.action';
// import { AddtocartComponent } from './modal/addtocart/addtocart.component';
// import { CartState } from 'src/app/shared/state/cart.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-product-box',
  templateUrl: './product-box.component.html',
  styleUrls: ['./product-box.component.scss']
})
export class ProductBoxComponent {

  @Input() product: any;

  // @Select(CartState.cartItems) cartItem$: Observable<Cart[]>;
  @ViewChild("addToCartModal") addToCartModal: any;

  public cart: any | null;
  public cartItem: any | null;

  constructor(
    private commonService: CommonService,
  ) {}

  ngOnInit() {
    this.cartItem = {
      imgUrl: this.product.imageUrls[0],
      name: this.product.name,
      price: this.product.currentPrice,
      productID: this.product.productID,
      quantity: 0,
    }

    this.commonService.getCart.subscribe({
      next: (res: any) => {
        if (res) {
          this.cart = res;
          if (this.cart && this.cart.items.length) {
            for (let item of this.cart.items) {
              if (item.productID == this.product.productID) {
                this.cartItem = item;
                break;
              }
            }
          }
        }
        else {
          this.cart = new Object();
        }
      }
    })
  }

}
