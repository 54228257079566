<div class="page-wrapper compact-wrapper" id="pageWrapper">
  <div class="page-header" [class.close_icon]="sharedService.collapseSidebar">
    <app-header></app-header>
  </div>
  <div class="page-body-wrapper">
    <div class="sidebar-wrapper" [class.close_icon]="sharedService.collapseSidebar">
      <!-- <app-sidebar-menu-skeleton [loading]="navServices.sidebarLoading"></app-sidebar-menu-skeleton> -->
      <app-sidebar></app-sidebar>
    </div>
    <div class="page-body">
      <div class="container-fluid" *ngIf="sharedService.showBackButton">
        <button (click)="location.back(); sharedService.showBackButton = false;" class="align-items-center btn btn-theme d-flex" style="max-width: 50px;">
          <i class="ri-arrow-left-line"></i>
        </button>
      </div>
      <router-outlet></router-outlet>
      <app-footer></app-footer>
    </div>
  </div>
</div>