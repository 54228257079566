<div class="onhover-dropdown" (click)="clickHeaderOnMobile()">
<div class="notification-box">
    <div>
        <i class="ri-notification-line"></i>
    </div>
    <span class="badge rounded-pill badge-theme" *ngIf="unreadNotificationCount">
        {{ unreadNotificationCount }}
    </span>
</div>
<!-- <ul class="notification-dropdown onhover-show-div" [class.active]="active" *ngIf="(notification$ | async)?.length">
    <li>
        <i class="ri-notification-line"></i>
        <h6 class="f-18 mb-0">{{ 'notifications' | translate }}</h6>
    </li>
    <li *ngFor="let notification of (notification$ | async) | slice:0:3">
        <p>
            <i class="ri-record-circle-line me-2 txt-primary"></i> {{ notification?.data?.message | summary:30 }}
        </p>
    </li>
    <li>
        <a [routerLink]="['/notification']" class="btn btn-theme">
            {{ 'check_all_notification' | translate }}
        </a>
    </li>
</ul> -->
</div>