import { Component } from '@angular/core';

@Component({
  selector: 'app-product-box-skeleton',
  templateUrl: './product-box-skeleton.component.html',
  styleUrls: ['./product-box-skeleton.component.scss']
})
export class ProductBoxSkeletonComponent {

}
