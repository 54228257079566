export class ProductImage {
    id?: string;
    fileName?: string;
    fileKey?: string;
    fileUrl?: string;
    fileType?: FileType;
    productId?: number;
    userName?: string;
    uploadedDate?: Date;
    metadatas?: any;
    selected?: boolean;
}

export enum FileType {
    ProductImage = 1,
    ProductThumbnail = 2,
    UserDocument = 3,
    UserAvatar = 4,
    PostImage = 5,
    PostContent = 6,
}
export enum FileTypeValue{
    ProductImage = "ProductImage",
    ProductThumbnail = "ProductThumbnail",
    UserDocument = "UserDocument",
    UserAvatar = "UserAvatar",
    PostImage = "PostImage",
    PostContent = "PostContent",
    HomeConfigImage = "HomeConfigImage",
}