import { Component, Input, PLATFORM_ID, Inject } from "@angular/core";
import { isPlatformBrowser } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { SharedService } from "@/app/shared/services/shared.service";
import { ConfigurationService } from "@/app/shared/services/configuration.service";
import { Menu } from "@/app/shared/interfaces/configuration.interface";
import { CommonService } from "@/app/shared/services/common.service";
import { AuthService } from "../../services/auth.service";
import { IsAuthurization } from "../../interfaces/role.interface";

declare var $: JQueryStatic;

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent {

  @Input() class: string | undefined;

  // @Select(AccountState.user) user$: Observable<AccountUser>;
  // @Select(AccountState.permissions) permissions$: Observable<Permission[]>;
  // @Select(SettingState.setting) setting$: Observable<Values>;
  // @Select(MenuState.menu) menu$: Observable<MenuModel>;

  public item: any;
  public menuItems: Menu[] = [];
  public permissions: number[] = [];
  public sidebarTitleKey: string = 'sidebar';
  public setting: any;

  public showMenuTitle: boolean = false;

  constructor(public sharedServices: SharedService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private menuService: ConfigurationService,
    private commonService: CommonService,
    private authService: AuthService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.menuItems?.forEach((menu: any) => {
          menu.active = false;
          this.activeMenuRecursive(menu, event.url);
        });
      }
    });
    this.authService.getPermission().subscribe(res => {
      this.permissions = res;
    })
  }

  ngOnInit() {
    this.getMenus();
    this.sortMenu(this.menuItems);
  }

  getMenus() {
    this.commonService.getSidebar.subscribe({
      next: (res: Menu[]) => {
        if (res && res.length) {
          this.menuItems = res;
          this.sortMenu(this.menuItems);
        }
      }
    })
  }

  sortMenu(menuItems: Menu[]) {
    menuItems.forEach(menu => {
      if (menu.children.length) {
        this.sortMenu(menu.children);
      }
    })
    menuItems.sort((a: Menu, b: Menu) => {
      if (a.order > b.order) return 1;
      if (a.order < b.order) return -1;
      return 1;
    });
  }

  hasMainLevelMenuPermission(acl_permission?: number[] | undefined) {
    let status = true;
    if (acl_permission?.length) {
      if (!acl_permission?.some(action => this.permissions?.includes(action))) {
        status = false;
      }
    }
    return status;
  }
  hasPermission(permission: number[] | undefined) {
    return IsAuthurization(this.permissions, permission);
  }
  sidebarToggle() {
    this.sharedServices.collapseSidebar = !this.sharedServices.collapseSidebar;
    
    if (this.sharedServices.collapseSidebar) {
      $('.sidebar-wrapper').addClass('close_icon');
      $('.sidebar-wrapper.close_icon').on('mouseenter', () => {
        setTimeout(() => {
          this.showMenuTitle = true;
        }, 100);
      });
      $('.sidebar-wrapper.close_icon').on('mouseleave', () => {
        setTimeout(() => {
          this.showMenuTitle = false;
        }, 100);
      });
    }
    else {
      $('.sidebar-wrapper').removeClass('close_icon');
    }
  }

  onItemSelected(item: Menu, onRoute: boolean = false) {
    this.menuItems.forEach((menu: Menu) => {
      this.deActiveAllMenu(menu, item);
    });
    if (!onRoute) {
      item.active = !item.active;
      if (item.active) {
        this.activeMenuRecursive(item, this.router.url);
      }
    }

  }

  activeMenuRecursive(menu: Menu, url: string, item?: Menu) {
    if (menu && menu.path && this.isActiveMenu(menu.path, url)) {
      if (item) {
        item.active = true;
        this.onItemSelected(item, true);
      }
      menu.active = true;
    }
    if (menu?.children?.length) {
      menu?.children.forEach((child: any) => {
        this.activeMenuRecursive(child, (url.charAt(0) !== '/' ? '/' + url : url.toString()), menu)
      })
    }
  }

  isActiveMenu(menuPath: string, url: string): boolean {
    url = url.charAt(0) !== '/' ? '/' + url : url;
    return menuPath == url;
  }

  deActiveAllMenu(menu: Menu, item: Menu) {
    if (menu && menu.active && menu.menuId != item.menuId) {
      menu.active = false;
    }
    if (menu?.children?.length) {
      menu?.children.forEach((child: any) => {
        this.deActiveAllMenu(child, item)
      })
    }
  }

  closeSidebar() {
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      if (window.innerWidth < 992) {
        this.sharedServices.collapseSidebar = false;
      }
    }
  }

}
