<div class="select-top-panel">
    <div>
        <input type="text" class="form-control" placeholder="{{ 'search_your_files' | translate }}" [formControl]="term">
    </div>
    <select class="form-select" (change)="onMediaChange($event)">
        <option value="">{{ 'sort_by_desc' | translate }}</option>
        <option value="newest">{{ 'sort_by_newest' | translate }}</option>
        <option value="oldest">{{ 'sort_by_oldest' | translate }}</option>
        <option value="smallest">{{ 'sort_by_smallest' | translate }}</option>
        <option value="largest">{{ 'sort_by_largest' | translate }}</option>
    </select>
</div>
<div class="content-section select-file-section py-0 ratio2_3 custom-box-loader">
    <div class="box-loader" *ngIf="loading else mediaContent">
        <!-- <app-loader [loaderClass]="'custom-loader-wrapper'"></app-loader> -->
    </div>
    <ng-template #mediaContent>
        <div class="row row-cols-xxl-6 row-cols-xl-5 row-cols-lg-4 row-cols-sm-3 row-cols-2 g-sm-3 g-2 media-library-sec ratio_square"
            *ngIf="imagesList && imagesList.length > 0">
            <div *ngFor="let image of imagesList; let i = index;">
                <div class="library-box">
                    <ng-container>
                        <input type="{{ !multiple ? 'radio' : 'checkbox' }}" name="image" [value]="image.id"
                            class="media-checkbox" id="{{image.id}}"
                            [checked]="image.selected"
                            (click)="clickImage($event, image)"
                            (change)="selectImage($event, image, url)" />
                        <label for="{{image.id}}">
                            <div class="ratio ratio-1x1">
                                <img [src]="image.fileUrl" class="img-fluid" alt="attachment">
                            </div>
                        </label>
                    </ng-container>
                    <ng-container>
                        <div ngbDropdown class="dropdown" *ngIf="deleteAction">
                            <a href="javascript:void(0)" ngbDropdownToggle>
                                <i class="ri-more-fill"></i>
                            </a>
                            <ul ngbDropdownMenu class="dropdown-menu dropdown-menu-end">
                                <a ngbDropdownItem
                                    class="d-flex align-items-center"
                                    (click)="DeleteModal?.openModal('delete', image)">
                                    <i class="ri-delete-bin-line me-2"></i> {{ 'delete' | translate }}
                                </a>
                            </ul>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </ng-template>
    <nav class="custom-pagination" *ngIf="attachment && attachment.data?.length">
        <app-pagination 
            [total]="totalItems" 
            [currentPage]="filter['page']" 
            [pageSize]="filter['paginate']"
            (setPage)="setPaginate($event)">
        </app-pagination>
    </nav>
    <app-no-data 
        [image]="'assets/svg/no-media.svg'" 
        [text]="'No Media Found'"
        *ngIf="!imagesList || !imagesList.length && !loading">
    </app-no-data>
</div>
<app-delete-modal 
    #deleteModal 
    (deleteItem)="onActionClicked($event.actionToPerform,$event.data)">
</app-delete-modal>