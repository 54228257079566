<div
  #fileUpload
  class="content-section drop-files-sec drop-zone"
  ngx-dropzone
  [accept]="fileType"
  [multiple]="multipleFiles"
  (change)="onSelect($event)"
>
  <ngx-dropzone-label>
    <div>
      <img class="pb-3" src="assets/svg/upload-file.svg" />
      <h4>{{title}}</h4>
      <h4>{{description}}</h4>
    </div>
  </ngx-dropzone-label>
  <div *ngIf="type.includes('image') else pdf">
    <ngx-dropzone-image-preview
    ngProjectAs="ngx-dropzone-preview"
    *ngFor="let f of files"
    [file]="f"
    [removable]="true"
    (removed)="onRemove(f)"
  >
    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
  </ngx-dropzone-image-preview>
  </div>
  <ng-template #pdf>
    <ngx-dropzone-preview
      ngProjectAs="ngx-dropzone-preview"
      *ngFor="let f of files"
      [file]="f"
      [removable]="true"
      (removed)="onRemove(f)"
    >
      <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
    </ngx-dropzone-preview>
  </ng-template>
</div>
