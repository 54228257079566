<div class="skeleton-loader" *ngIf="loading">
    <div class="logo-wrapper logo-wrapper-center">
        <h2 class="text-white">{{ 'fastkart' | translate }}</h2>
        <div class="back-btn"><i class="fa fa-angle-left"></i></div>
        <div class="toggle-sidebar"><i class="ri-apps-line status_toggle middle sidebar-toggle"></i></div>
    </div>
    <nav class="sidebar-main">
        <div class="left-arrow" id="left-arrow">
            <i data-feather="arrow-left"></i>
        </div>
        <div id="sidebar-menu">
            <ul class="sidebar-links" id="simple-bar">
                <li class="back-btn"></li>
                <li class="sidebar-list" *ngFor="let item of skeletonItems">
                    <a class="link-nav sidebar-link sidebar-title">
                        <i></i>
                        <div class="sidebar-main-link"></div>
                    </a>
                </li>
            </ul>
        </div>
    </nav>
</div>