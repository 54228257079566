<div [class]="class">
  <div class="logo-wrapper logo-wrapper-center px-3">
    <a [routerLink]="['/dashboard']" *ngIf="!setting; else siteTitle">
      <img
        class="img-fluid for-white"
        [src]="
          'assets/images/logo/DuocVuong-white-logo.png'
        "
        alt="logo"
      />
    </a>
    <ng-template #siteTitle>
      <a [routerLink]="['/dashboard']">
        <h2 class="text-white">
          <!-- {{
            setting.general?.site_title
              ? setting.general?.site_title.split(" ")[0]
              : "Logo Here"
          }} -->
        </h2>
      </a>
    </ng-template>
    <img
      class="img-fluid logo-sm"
      [src]="'assets/images/logo/DuocVuong-white-logo.png'"
      alt="logo"
      *ngIf="!setting; else smallLogo"
    />
    <ng-template #smallLogo>
      <h2 class="text-white logo-sm">
        <!-- {{
          setting.general?.site_title
            ? setting.general?.site_title
                .substr(0, 2)
                ?.toString()
                ?.toUpperCase()
            : "Logo"
        }} -->
      </h2>
    </ng-template>
    <div class="back-btn">
      <i class="fa fa-angle-left"></i>
    </div>
    <div class="toggle-sidebar" (click)="sidebarToggle()">
      <i class="ri-apps-line status_toggle middle sidebar-toggle"></i>
    </div>
  </div>
  <nav class="sidebar-main">
    <div class="left-arrow" id="left-arrow">
      <i data-feather="arrow-left"></i>
    </div>
    <div id="sidebar-menu">
      <ul class="sidebar-links" id="simple-bar">
        <li class="back-btn"></li>
        <div
          *ngTemplateOutlet="recursiveMenu; context: { $implicit: menuItems }"
        ></div>
        <ng-template #recursiveMenu let-menus>
          <ng-container *ngFor="let menu of menus">
            <ng-container *ngIf="menu.isVisible">
              <li [class.sidebar-list]="menu.children.length > 0" style="min-height: 45px;">
                <a
                  [class]="'debar-link sidebar-title link-nav sidebar-link'"
                  (click)="onItemSelected(menu)"
                  [routerLink]="menu.path"
                  *ngIf="menu.children.length">
                  <span>
                    <div class="d-flex align-items-center">
                      <i class="{{ menu.icon }}" *ngIf="menu.icon"></i>
                      <div class="sidebar-main-link">
                        <!-- <img *ngIf="menu.icon" src="assets/svg/{{ menu.icon }}" /> -->
                        <div class="menu-item-name">{{ menu.name }}</div>
                      </div>
                    </div>
                  </span>
                  <div class="according-menu" *ngIf="menu.children">
                    <i
                      class="ri-arrow-{{
                        menu.active ? 'down' : 'right'
                      }}-s-line"
                    ></i>
                  </div>
                </a>
                <!-- Submenu Link -->
                <a
                  href="javascript:void(0)"
                  *ngIf="menu.children.length == 0"
                  [class]="'debar-link sidebar-title link-nav sidebar-link'"
                  [class.active]="menu.active"
                  [routerLink]="menu.path"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  (click)="closeSidebar()"
                >
                  <div class="d-flex">
                    <i class="{{ menu.icon }}" *ngIf="menu.icon"></i>
                    <!-- <img *ngIf="menu.icon" src="assets/svg/{{ menu.icon }}" /> -->
                    <div class="menu-item-name">{{ menu.name }}</div>
                  </div>
                </a>
                <ul
                  class="sidebar-submenu"
                  [class.show]="menu.children && menu.active"
                >
                  <ng-container
                    *ngTemplateOutlet="
                      recursiveMenu;
                      context: { $implicit: menu.children }
                    "
                  ></ng-container>
                </ul>
              </li>
            </ng-container>
          </ng-container>
        </ng-template>
      </ul>
    </div>
  </nav>
</div>
