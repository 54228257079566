<ng-template #mediaModal let-modal>
    <div class="modal-header">
        <h2>Media</h2>
        <app-button [class]="'btn btn-close'" [spinner]="false" [id]="'media_close_btn'" (click)="modal.dismiss('Cross click')">
            <i class="ri-close-line"></i>
        </app-button>
    </div>
    <div class="modal-body">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
            <li [ngbNavItem]="'select'" *ngIf="selectMedia">
                <a ngbNavLink>Chọn ảnh</a>
                <ng-template ngbNavContent>
                    <app-media-box 
                        [imagesList]="imagesList"
                        [multiple]="multipleImage"
                        [url]="url"
                        [deleteAction]="true"
                        [selectedImages]="selectedImages"
                        (setImage)="setImage($event)">
                    </app-media-box>
                </ng-template>
            </li>
            <li [ngbNavItem]="'upload'" >
                <a ngbNavLink>Tải lên</a>
                <ng-template ngbNavContent>
                    <div class="content-section drop-files-sec" ngx-dropzone [accept]="'image/*'" (change)="onSelect($event)">
                        <ngx-dropzone-label>
                            <div>
                                <h2>{{ 'drop_files_here' | translate }}</h2>
                            </div>
                        </ngx-dropzone-label>
                        <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" 
                            *ngFor="let f of files" [file]="f" [removable]="true" (removed)="onRemove(f)">
                            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                        </ngx-dropzone-image-preview>
                    </div>
                </ng-template>
            </li>
           
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
    <div class="modal-footer">
        <div class="left-part"></div>
        <div class="right-part">
            <app-button 
                [class]="'align-items-center btn btn-theme d-flex'" 
                [id]="'media_btn'"
                (click)="active == 'upload' ? addMedia(nav) : selectedMedia(modal)">
                {{ active === 'upload' ? 'Tải lên' : 'Xác nhận' }}
            </app-button>
        </div>
    </div>
</ng-template>