import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgxDropzoneChangeEvent, NgxDropzoneComponent } from 'ngx-dropzone';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrl: './file-upload.component.scss'
})
export class FileUploadComponent {

  @Input() files: File[] | any;
  @Input() fileType: string = "*";
  @Input() title: string = "";
  @Input() description: string = "";
  @Input() multipleFiles: boolean = true;

  public type :string = "";
  trigger: boolean = false;

  @Output() select: EventEmitter<NgxDropzoneChangeEvent> = new EventEmitter();
  @Output() remove: EventEmitter<File> = new EventEmitter();

  @ViewChild('fileUpload') fileUpload: NgxDropzoneComponent;

  onSelect(event: NgxDropzoneChangeEvent) {
    this.type = event.addedFiles[0].type;
    this.select.emit(event);
  }

  onRemove(event: File) {
    this.remove.emit(event);
  }
}
