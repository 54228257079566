import { Component, Inject, ViewChild, Input, Output, EventEmitter, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FormControl } from '@angular/forms';
import { Observable, debounceTime, distinctUntilChanged, firstValueFrom } from 'rxjs';
import { DeleteModalComponent } from "../../../../shared/components/ui/modal/delete-modal/delete-modal.component";
import { SharedService } from '@/app/shared/services/shared.service';
import { ProductImage } from '@/app/shared/interfaces/file.interface';
import { FileService } from '@/app/shared/services/file.service';
import { HttpStatusCode } from '@angular/common/http';

@Component({
  selector: 'app-media-box',
  templateUrl: './media-box.component.html',
  styleUrls: ['./media-box.component.scss']
})
export class MediaBoxComponent {

  @ViewChild("deleteModal") DeleteModal: DeleteModalComponent | undefined;

  @Input() selectedImages: any[] = [];
  @Input() imagesList: any[] = [];
  @Input() multiple: boolean = false;
  @Input() url: boolean = false;
  @Input() loading: boolean = false;
  @Input() deleteAction: boolean = true;

  @Output() setImage: EventEmitter<[] | any> = new EventEmitter();
  @Output() setDeleteImage: EventEmitter<[] | any> = new EventEmitter();

  public term = new FormControl();
  public filter = {
    'search': '',
    'field': '', 
    'sort': '', // current Sorting Order
    'page': 1, // current page number
    'paginate': 48, // Display per page,
  };
  public totalItems: number = 0;
  public attachment: any;
  public images: ProductImage[] = [];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private sharedService: SharedService,
    private fileService: FileService
  ) {
    this.term.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(
        (data: string) => {
        this.filter.search = data;
        this.getAttachments(this.filter);
    });
  }

  ngOnInit() {
    for (let image of this.imagesList) {
      if (this.selectedImages && this.selectedImages.some(img => img.id == image.id)) {
        image.selected = true;
      }
      else {
        image.selected = false;
      }
    }
    this.images = [... this.selectedImages];
  }

  getAttachments(filter: any, loader?: boolean) {
    this.loading = true;
    if(!loader)
      this.renderer.addClass(this.document.body, 'loader-none');
  }

  onMediaChange(event: Event) {
    this.filter.sort = (<HTMLInputElement>event.target).value;
    this.getAttachments(this.filter)
  }

  onActionClicked(action: string, data: any) {

    this.fileService.deleteFile(data.id).subscribe((res : any) => {
      if (res.statusCode == HttpStatusCode.Ok) {
        this.imagesList.splice(this.imagesList.findIndex(img => img.id == data.id), 1);
      }
    });
  }

  selectImage(event: Event, image: any, url: boolean) {
    if(this.multiple) {
      const index = this.images.indexOf(image);
      if((<HTMLInputElement>event.target).checked) { 
        if(index == -1) this.images.push(image) 
      } else {
        this.images = this.images.filter(image => image.id != (<HTMLInputElement>event.target).value);
      }
    } else {
      this.images = <any>image;
    }

    if(url) {
      this.images = <any>image;
    }
    this.setImage.emit(this.images);
  }

  clickImage(event: Event, image: any) {
    if (!this.multiple) {
      image.selected = !image.selected;
      if (image.selected) {
        this.setImage.emit(image);
      }
      else {
        this.setImage.emit(null);
      }
    }
  }

  setPaginate(data: number) {
    this.filter.page = data;
    this.getAttachments(this.filter);
  }

  ngOnDestroy() {
    this.renderer.removeClass(this.document.body, 'loader-none');
  }

}
