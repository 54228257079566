import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Category } from '../interfaces/category.interface';
import { Menu } from '../interfaces/configuration.interface';
import { Province } from '../interfaces/province.interface';

// import { Cart, CartItem } from '../interface/cart.interface';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  public authEvent: EventEmitter<boolean> = new EventEmitter();
  public productEvent: EventEmitter<boolean> = new EventEmitter();
  public selectType: EventEmitter<string> = new EventEmitter();

  private cart = new BehaviorSubject<any>(null);
  public getCart = this.cart.asObservable();
  setCart(cart: any) {
    this.cart.next(cart);
  }

  private provinces = new BehaviorSubject<Province[]>([]);
  public getProvinces = this.provinces.asObservable();
  setProvinces(provinces: Province[]) {
    this.provinces.next(provinces);
  }

  private cartItem = new BehaviorSubject<any>(null);
  public getCartItem = this.cartItem.asObservable();
  setCartItem(cartItem: any) {
    this.cartItem.next(cartItem);
  }

  private categories = new BehaviorSubject<Category[]>([]);
  public getCategories = this.categories.asObservable();
  setCategories(categories: Category[]) {
    this.categories.next(categories);
  }

  private category = new BehaviorSubject<Category>(new Category());
  public getCategory = this.category.asObservable();
  setCategory(category: Category) {
    this.category.next(category!);
  }

  private configuration = new BehaviorSubject<any>(null);
  public getConfiguration = this.configuration.asObservable();
  setMenu(config: any) {
    this.configuration.next(config);
  }

  private sidebar = new BehaviorSubject<Menu[]>([]);
  public getSidebar = this.sidebar.asObservable();
  setSidebar(sidebar: Menu[]) {
    this.sidebar.next(sidebar);
  }

  getPager(totalItems: number, currentPage: number, pageSize: number, paginateRange: number) {

    // calculate total pages
    let totalPages = Number(Math.ceil(Number(totalItems) / Number(pageSize)));

    // Paginate Range


    // ensure current page isn't out of range
    if (Number(currentPage) < 1) {
        currentPage = 1;
    } else if (Number(currentPage) > Number(totalPages)) {
        currentPage = Number(totalPages);
    }

    let startPage: number, endPage: number;
    if (Number(totalPages) <= Number(paginateRange)) {
        // Less than or equal to the paginateRange
        startPage = 1;
        endPage = Number(totalPages);
    } else if (Number(currentPage) <= Number(Math.floor(Number(paginateRange) / 2))) {
        // Near the beginning
        startPage = 1;
        endPage = Number(paginateRange);
    } else if (Number(currentPage) >= Number(totalPages) - Number(Math.floor(Number(paginateRange) / 2))) {
        // Near the end
        startPage = Number(totalPages) - Number(paginateRange) + 1;
        endPage = Number(totalPages);
    } else {
        // In the middle
        startPage = Number(currentPage) - Number(Math.floor(Number(paginateRange) / 2));
        endPage = Number(currentPage) + Number(Math.floor(Number(paginateRange) / 2));
    }

    // calculate start and end item indexes
    let startIndex = (Number(currentPage) - 1) * Number(pageSize);
    let endIndex = Math.min(Number(startIndex) + Number(pageSize) - 1, Number(totalItems) - 1);


    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array((Number(endPage) + 1) - Number(startPage)).keys()).map(i => Number(startPage) + Number(i));

    // return object with all pager properties required by the view
    return {
        totalItems: totalItems,
        currentPage: currentPage,
        pageSize: pageSize,
        totalPages: totalPages,
        startPage: startPage,
        endPage: endPage,
        startIndex: startIndex,
        endIndex: endIndex,
        pages: pages
    };
  }

  slugify(str: string): string {
    const a = 'àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;'
    const b = 'aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------'
    const p = new RegExp(a.split('').join('|'), 'g')
    return str.toString().toLowerCase()
      .replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, 'a')
      .replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, 'e')
      .replace(/i|í|ì|ỉ|ĩ|ị/gi, 'i')
      .replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, 'o')
      .replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, 'u')
      .replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, 'y')
      .replace(/đ/gi, 'd')
      .replace(/\s+/g, '-')
      .replace(p, c => b.charAt(a.indexOf(c)))
      .replace(/&/g, '-and-')
      .replace(/[^\w\-]+/g, '')
      .replace(/\-\-+/g, '-')
      .replace(/^-+/, '')
      .replace(/-+$/, '')
  }

  /**
   * Parse JWT
   * @param token 
   * @returns json object
  */
  parseJwt (token: string) {
    try {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
  
      return JSON.parse(jsonPayload);
    }
    catch {
      return null;
    }
  }
}
