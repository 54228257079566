<ng-template #confirmationModal let-modal>
    <div class="modal-body">
        <i class="ri-alert-line icon-box wo-bg"></i>
        <!-- <h5 class="modal-title">{{ 'confirmation' | translate }}</h5> -->
        <h5 class="modal-title">{{ confirmTitle }}</h5>
        <!-- <p>{{ 'confirmation_massage' | translate }}</p> -->
        <p>{{ message }}</p>
        <div class="button-box">
            <app-button 
                [class]="'btn-theme btn-md fw-bold btn'" 
                [id]="'confirm_yes_btn'"
                (click)="confirm()">
                OK
            </app-button>
        </div>
    </div>
  </ng-template>