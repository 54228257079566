import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@/environments/environment";
import { Category } from "../interfaces/category.interface";

@Injectable({
  providedIn: "root",
})
export class CategoryService {

  constructor(private http: HttpClient) {}

  getCategories() {
    return this.http.get<Category[]>(`${environment.baseURL}get-all-category`);
  }

  createCategory(body: string) {
    return this.http.post(`${environment.baseURL}create-category`, body, {
      headers: {
        "Content-Type": "application/json",
        },
      }
    );
  }

  updateCategory(body: string) {
    return this.http.put(`${environment.baseURL}update-category`, body, {
      headers: {
        "Content-Type": "application/json",
        },
      }
    );
  }

  deleteCategory(categoryId: string) {
    return this.http.delete(`${environment.baseURL}delete-category/${categoryId}`);
  }

}
