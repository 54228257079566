import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@/environments/environment';
import { DeliveryAddressModel, Role, User, UserPagingModel } from '../interfaces/user.interface';
import { ResponsePagingModel } from '../interfaces/pagination.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) { }

  getUsers(body: UserPagingModel): Observable<ResponsePagingModel<User>>
  getUsers(body: string): Observable<ResponsePagingModel<User>>
  getUsers(body: unknown): Observable<ResponsePagingModel<User>> {
    let request: string;
    if (typeof body === 'object') {
      request = JSON.stringify(body);
    } else {
      request = body as string;
    }
    return this.http.post<ResponsePagingModel<User>>(`${environment.baseURL}user/all`, request, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  getUser(username: string) {
    return this.http.get<User>(`${environment.baseURL}user/${username}`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    });
  }

  getUserAdmin(username: string) {
    return this.http.get<User>(`${environment.baseURL}user-admin/${username}`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    });
  }

  getAllRoles() {
    return this.http.get<Role[]>(`${environment.baseURL}role`);
  }

  createUser(body: string) {
    return this.http.post<boolean>(`${environment.baseURL}user`, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  checkUserExist(username: string) {
    return this.http.get<boolean>(`${environment.baseURL}user/validate-user-login?userName=${username}`);
  }

  updateUser(body: string) {
    return this.http.put<boolean>(`${environment.baseURL}user`, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  updateUserAdmin(body: string) {
    return this.http.put<boolean>(`${environment.baseURL}user/admin-update`, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  resetUserPass(body : string){
    return this.http.put<boolean>(`${environment.baseURL}user/reset-password`, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  getUserAddress() {
    return this.http.get<DeliveryAddressModel>(`${environment.baseURL}user/address`);
  }
}
