import { Category } from "@/app/shared/interfaces/category.interface";
import { ConfigurationAction, Configuration, Menu } from "@/app/shared/interfaces/configuration.interface";
import { CategoryService } from "@/app/shared/services/category.service";
import { CommonService } from "@/app/shared/services/common.service";
import { ConfigurationService } from "@/app/shared/services/configuration.service";
import { ProvinceService } from "@/app/shared/services/province.service";
import { SharedService } from "@/app/shared/services/shared.service";
import { Location } from "@angular/common";
import { Component } from "@angular/core";
import { Router } from "@angular/router";
// import { Store } from "@ngxs/store";
// import { NavService } from "../../../services/nav.service";
// import { GetNotification } from './../../../action/notification.action';
// import { GetBadges } from './../../../action/menu.action';
// import { GetUserDetails } from './../../../action/account.action';

@Component({
  selector: "app-content",
  templateUrl: "./content.component.html",
  styleUrls: ["./content.component.scss"],
})
export class ContentComponent {

  userName = JSON.parse(localStorage.getItem("user")!).username;
  retry: boolean = false;

  constructor(
    public sharedService: SharedService,
    private configurationService: ConfigurationService,
    private commonService: CommonService,
    private categoryService: CategoryService,
    private router: Router,
    public location: Location,
    private provinceService: ProvinceService,
  ) {
    // this.store.dispatch(new GetBadges());
    // this.store.dispatch(new GetNotification());
    // this.store.dispatch(new GetUserDetails()).subscribe({
    //   complete: () => {
    //     this.navServices.sidebarLoading = false;
    //   }
    // });
  }

  ngOnInit() {
    this.sharedService.collapseSidebar = false;

    this.getConfiguration(this.userName);
    this.getCategories();
    this.getSidebar();
    this.getProvincesInfo();
  }
  
  getConfiguration(userName?: string) {
    this.configurationService.getConfiguration().subscribe({
      next: (res: Configuration) => {
        if (res.menu) {
          this.commonService.setMenu(res);
        }
        // else {
        //   // create user's configuration for the first time based on common configuration
        //   const model = {
        //     theme: res.theme,
        //     menu: res.menu,
        //     userName: this.userName
        //   }
        //   this.configurationService.upsertConfigurationForUser(JSON.stringify(model), ConfigurationAction.Create).subscribe({
        //     next: (res: any) => {
        //       if (res) {
        //         this.commonService.setMenu(res);
        //       }
        //     },
        //     error: (error: any) => {
        //       this.router.navigateByUrl("/login", { replaceUrl: true });
        //       console.log(error);
        //     }
        //   })
        // }
      },
      error: (error: any) => {
        if (!this.retry) {
          this.retry = true;
          // this.getConfiguration();
        }
        console.log(error);
      }
    })
  }

  getSidebar() {
    this.configurationService.getSideBar().subscribe({
      next: (res: Menu[]) => {
        if (res) {
          this.commonService.setSidebar(res);
        }
      }
    })
  }

  getProvincesInfo() {
    //call api get provinces
    this.provinceService.getProvinceInfo().subscribe((res: any) => {
      this.commonService.setProvinces(res);
    })
  }

  getCategories() {
    this.categoryService.getCategories().subscribe({
      next: (res: Category[]) => {
        if (res) {
          this.commonService.setCategories(res);
        }
      }
    })
  }
}
