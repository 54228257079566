<ul class="image-select-list cursor-pointer">
    <li class="choosefile-input" (click)="MediaModal?.openModal()">
        <i *ngIf="multipleImage else thumbnail" class="ri-add-line"></i>
        <ng-template #thumbnail>
            <img *ngIf="showImage else icon" src="{{ showImage }}" alt="image" class="img-fluid">
        </ng-template>
        <ng-template #icon>
            <i class="ri-add-line"></i>
        </ng-template>
    </li>
    <li *ngFor="let img of showImages; let i = index;">
        <img src="{{ img }}" alt="image" class="img-fluid">
        <a href="javascript:void(0)" class="remove-icon" id="remove-icon{{i}}" (click)="remove(i, 'multiple');"><i class="ri-close-line text-white"></i></a>
    </li>
    <!-- <li *ngIf="showImage">
        <img src="{{ showImage }}" alt="image" class="img-fluid">
        <a href="javascript:void(0)" class="remove-icon" (click)="remove(showImage?.id, 'single')"><i class="ri-close-line text-white"></i></a>
    </li> -->
    <li *ngIf="showImageUrl">
        <img src="{{ showImageUrl }}" alt="image" class="img-fluid">
        <a href="javascript:void(0)" class="remove-icon" (click)="remove(1, 'single_image_url')"><i class="ri-close-line text-white"></i></a>
    </li>
</ul>
<p class="help-text" *ngIf="helpText">{{ helpText }}</p>
<app-media-modal 
    [multipleImage]="multipleImage" 
    [imagesList]="imagesList"
    [url]="url"
    [selectedImages]="selectedImages"
    (selectImage)="selectImage($event, url)"
    [fileType]="fileType"
    (newImagesList)="newImagesList($event)"
    #mediaModal>
</app-media-modal>
