import { Component, ViewChild } from '@angular/core';
// import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs';
// import { Logout } from '../../.././../../shared/action/auth.action';
// import { AccountUser } from '../../.././../../shared/interface/account.interface';
// import { AccountState } from '../../.././../../shared/state/account.state';
import { AuthService } from '@/app/shared/services/auth.service';
import { Router } from '@angular/router';

import { ConfirmationModalComponent } from '@/app/shared/components/ui/modal/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {

  // @Select(AccountState.user) user$: Observable<AccountUser>;

  @ViewChild("confirmationModal") ConfirmationModal: ConfirmationModalComponent | undefined;

  public active: boolean = false;
  public user: any;

  constructor(
    private authService: AuthService,
    private router: Router 
  ) { }

  ngOnInit() {
    this.user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")!) : null;
  }

  clickHeaderOnMobile() {
    this.active = !this.active
  }

  logout(value: boolean) {
    if (value) {
      this.authService.logout();
      this.router.navigateByUrl("/auth/login", { replaceUrl: true });
    }
  }
}
