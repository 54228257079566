<div class="product-box">
    <div class="product-image">
        <img [src]="product?.imageUrls
                ? product?.imageUrls[0]
                : 'assets/images/product.png'" class="img-fluid blur-up lazyload" alt="product">
    </div>
    <div class="product-detail">
        <h6 class="name name-2 h-100">{{product?.name}}</h6>
        <h6 class="sold weight text-content fw-normal text-capitalize">
            <span *ngIf="product?.unit">{{ product?.unit }}</span>
        </h6>
        <div class="counter-box">
            <!-- <h6 class="sold theme-color">{{product?.currentPrice | currencySymbol}}</h6> -->
            <h5 class="sold text-content">
                <span class="theme-color price">{{ product.pricingTablePrice | currencySymbol }}</span>
                <del *ngIf="product.pricingTablePrice && product.pricingTablePrice != product.currentPrice">{{ product.currentPrice | currencySymbol }}</del>
              </h5>
            <div class="addtocart_btn">
                <app-counter [cartItem]="cartItem" [style]="'text-align: center; padding: 8px 0;'" style="width: 100%;"></app-counter>
            </div>
        </div>
    </div>
</div>
<app-addtocart #addToCartModal></app-addtocart>