<div #dropdownContainer (clickOutside)="clickOutside()">
    <div class="position-relative">
        <nav class="category-breadcrumb-select" (click)="toggleDropdown($event)">
            <ol class="breadcrumb">
                <ng-template *ngIf="selectedPills.length; then content else select"></ng-template>
                <ng-template #content>
                    <li class="breadcrumb-pills" *ngFor="let pills of selectedPills">
                        <span class="badge badge-pill badge-primary">{{ pills[displayKey] }}
                            <i class="ri-close-line" *ngIf="!disabled" (click)="removeItem(pills); $event.stopPropagation()"></i>
                        </span>
                    </li>
                </ng-template>
                <ng-template #select>
                    <li class="breadcrumb-item">
                        <a href="javascript:void(0)">{{ placeholder }}</a>
                    </li>
                </ng-template>
            </ol>
        </nav>
        <a class="cateogry-close-btn d-inline-block" [class.show]="isOpen" (click)="toggleDropdown($event)">
            <i class="ri-arrow-down-s-line down-arrow"></i>
            <i class="ri-close-line close-arrow"></i>
        </a>
        <div class="select-category-box mt-2 dropdown-open" [class.show]="isOpen">
            <input *ngIf="config?.isSearchable" class="form-control search-input" placeholder="Search here.." [formControl]="term">
            <div class="category-content">
                <nav class="category-breadcrumb" aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item" *ngIf="!optionsData?.length">
                            <a href="javascript:void(0)">{{ 'No Data Found.' }}</a>
                        </li>
                        <li class="breadcrumb-item" *ngIf="optionsData?.length">
                            <a href="javascript:void(0)" (click)="clearOptions()">{{ 'Tất cả' }}</a>
                        </li>
                        <li class="breadcrumb-item" *ngFor="let breadCrumb of breadCrumbValues; let i = index;">
                            <a href="javascript:void(0)" (click)="changeTo(breadCrumb)">{{ breadCrumb[displayKey] }}</a>
                        </li>
                    </ol>
                </nav>
                <div class="category-listing" *ngIf="optionsData?.length">
                    <ul>
                        <app-dropdown-list 
                            [key]="displayKey" 
                            [data]="option" 
                            [selectedPillIds]="selectedIds"
                            [subArrayKey]="subArrayKey" 
                            (selected)="onSelect($event)"
                            (subItemClicked)="subItemClicked($event)" 
                            *ngFor="let option of optionsData">
                        </app-dropdown-list>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>