<div class="header-wrapper m-0" [class.skeleton-header]="sharedServices.sidebarLoading">
    <div class="header-logo-wrapper p-0">
        <div class="toggle-sidebar" checked="checked">
            <i (click)="sidebarToggle()" class="ri-apps-line status_toggle middle sidebar-toggle"></i>
            <a [routerLink]="['/dashboard']"
                *ngIf="!setting; else siteTitle">
                <img [src]="'assets/images/logo/DuocVuong-white-logo.png'" class="img-fluid" alt="header-logo">
            </a>
            <ng-template #siteTitle> 
                <a [routerLink]="['/dashboard']">
                    <h2 class="text-white">
                        <!-- {{setting.general?.site_title ? setting.general?.site_title.split(' ')[0] : 'Logo Here'}} -->
                    </h2>
                </a>
            </ng-template>
        </div>
    </div>
    <app-search></app-search>
    <div class="nav-right right-header p-0">
        <!-- <div class="header-btns d-none d-lg-flex">
            <a [routerLink]="['/product/add']" class="btn btn-outline" *hasPermission="PermissionAction.CreatePost">
                {{ 'add_product' | translate }}
            </a>
            <a [routerLink]="['/order/add']" class="btn btn-animation" *hasPermission="PermissionAction.CreateOrder">
                {{ 'add_order' | translate }}
            </a>
        </div> -->
        <ul class="nav-menus">
            <li>
                <span class="header-search" (click)="clickHeaderOnMobile()">
                    <i class="ri-search-line"></i>
                </span>
            </li>
            <li>
                <app-languages></app-languages>
            </li>
            <li>
                <app-notification></app-notification>
            </li>
            <li>
               <app-mode></app-mode>
            </li>
            <app-profile></app-profile>
        </ul>
    </div>
</div>