<div style="display: flex">
  <app-button
    [class]="'btn qty-left-minus'"
    [style]="'padding: calc(7px + 3 * (100vw - 320px) / 1600) calc(6px + 16 * (100vw - 320px) / 1600)'"
    [id]="'add-to-cart' + cartItem.productID"
    [spinner]="false"
    (click)="updateQuantity(cartItem, -1)"
  >
    <span>
      <i
        class="ri-subtract-line"
        *ngIf="cartItem.quantity > 1 || cartItem.quantity == 0"
      ></i>
      <i class="ri-delete-bin-line" *ngIf="cartItem.quantity == 1"></i>
    </span>
  </app-button>
  <input
    class="form-control input-number qty-input"
    [style]="style"
    type="number"
    name="quantity"
    [(ngModel)]="cartItem.quantity"
    (change)="updateQuantity(cartItem, 0)"
  />
  <app-button
    [class]="'btn qty-left-minus'"
    [style]="'padding: calc(7px + 3 * (100vw - 320px) / 1600) calc(6px + 16 * (100vw - 320px) / 1600)'"
    [id]="'qty-left-minus' + cartItem.productID"
    [spinner]="false"
    (click)="updateQuantity(cartItem, 1)"
  >
    <i class="ri-add-line"></i>
  </app-button>
</div>
