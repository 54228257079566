import { Component, Inject, Input, PLATFORM_ID, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { GiftService } from '@/app/shared/services/gift.service';
import { NgbDate, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PointUsage, User } from '@/app/shared/interfaces/user.interface';
import { UserService } from '@/app/shared/services/user.service';

@Component({
  selector: 'app-gift',
  templateUrl: './gift.component.html',
  styleUrls: ['./gift.component.scss']
})


export class GiftComponent {

  @Input() userName: string;

  private destroy$ = new Subject<void>();

  public user: User;

  giftList: PointUsage[] = [];

  headerColumns = [
    "Thời gian",
    "Nội dung",
    "Số lá xanh",
    "Mã đơn hàng"
  ];

  giftDescription: any = {
    icon: 'assets/svg/diamond.svg',
    caption: 'Đặc quyền ưu đãi LÁ XANH',
    descriptions: [
      'Mỗi 05 lá xanh được quy đổi 01 phần quà tương ứng',
      'Quý khách thực hiện đổi quà khi lên đơn hàng trên website '
    ]
  };

  public startTime: any = {
    date: {} as NgbDate,
    time: {} as NgbTimeStruct,
  };
  public endTime: any = {
    date: {} as NgbDate,
    time: {} as NgbTimeStruct,
  }

  searchBody: {
    limit: number,
    userName: string,
    fromDate?: Date,
    toDate?: Date,
  };

  constructor(
    private userService: UserService,
    private toastService: ToastrService,
    private giftService: GiftService,
  ) { }

  ngOnInit() {
    this.userService.getUserAdmin(this.userName).subscribe({
      next: (user: User) => {
        if (user && user.userName) {
          this.user = user;

          this.searchBody = {
            limit: 100,
            userName: this.user.userName,
          };
          this.getGiftHistoryList();
        }
      }
    });
    // this.getGiftDescription();

    this.giftService.reloadGiftPointHistory$.subscribe({
      next: (res: boolean) => {
        if (res) {
          this.getGiftHistoryList();
        }
      }
    });
  }

  getGiftHistoryList() {
    this.giftService.getGiftHistoryList(JSON.stringify(this.searchBody)).subscribe({
      next: (res: any) => {
        if (res && res.data) {
          this.giftList = res.data;
        }
      }
    });
  }

  getGiftDescription() {
    let body = JSON.stringify({
      userId: this.user.id,
      fromDate: new Date(),
      toDate: new Date(),
    });
    this.giftService.getGiftDescription(body).subscribe({
      next: (res: any) => {
        this.giftDescription = res;
      }
    });
  }

  search() {
    let toDay = new Date();
    this.searchBody = {
      limit: 100,
      userName: this.user.userName,
    };

    if (this.startTime.date?.year && !this.endTime.date?.year) {
      this.searchBody.fromDate = new Date(
        this.startTime.date?.year,
        this.startTime.date?.month - 1,
        this.startTime.date?.day,
        0, 0, 0, 0
      );

      this.searchBody.toDate = new Date(toDay.getFullYear(), toDay.getMonth(), toDay.getDate(),
       23, 59, 59, 999
      );
    }

    if (this.endTime.date?.year && !this.startTime.date?.year) {
      this.searchBody.toDate = new Date(
        this.endTime.date?.year,
        this.endTime.date?.month - 1,
        this.endTime.date?.day,
        23, 59, 59, 999
      );
      this.searchBody.fromDate = new Date(1, 1, 1)

    }

    if (this.startTime.date?.year && this.endTime.date?.year) {
      this.searchBody.fromDate = new Date(
        this.startTime.date?.year,
        this.startTime.date?.month - 1,
        this.startTime.date?.day,
        0, 0, 0, 0
      );

      this.searchBody.toDate = new Date(
        this.endTime.date?.year,
        this.endTime.date?.month - 1,
        this.endTime.date?.day,
        23, 59, 59, 999
      );

      if (this.searchBody.fromDate! > this.searchBody.toDate!) {
        this.toastService.error('Ngày bắt đầu và kết thúc không hợp lệ!', '', { positionClass: 'toast-top-right' });
        return;
      }
    }

    this.getGiftHistoryList();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
