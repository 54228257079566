import { Configuration, Theme } from '@/app/shared/interfaces/configuration.interface';
import { CommonService } from '@/app/shared/services/common.service';
import { Component } from '@angular/core';

@Component({
  selector: 'app-mode',
  templateUrl: './mode.component.html',
  styleUrls: ['./mode.component.scss']
})
export class ModeComponent {
  
  theme: Theme | undefined;

  constructor(
    private commonService: CommonService
  ) { }

  ngOnInit() {
    this.commonService.getConfiguration.subscribe({
      next: (res: Configuration) => {
        if (res && res.theme) {
          this.theme = res.theme;
          document.documentElement.style.setProperty('--theme-color', this.theme!.primary);
        }
      }
    })
  }

  customizeLayoutDark() {
    document.body.classList.toggle('dark-only');
    document.documentElement.style.setProperty('--theme-color', '#adbca123');
  }
}
