import { Component, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { CartService } from '@/app/shared/services/cart.service';
import { CommonService } from '@/app/shared/services/common.service';
// import { CartItem } from '@/app/shared/interface/cart.interface';

@Component({
  selector: 'app-counter',
  templateUrl: './counter.component.html',
  styleUrl: './counter.component.scss'
})
export class CounterComponent {

  @Input() cartItem: any;
  @Input() style: string | undefined;

  public product: any;
  public quantity: number = 0;

  constructor(private cartService: CartService,
    private commonService: CommonService,
    private toastService: ToastrService
  ) {

  }

  updateQuantity(item: any, value?: number) {
    if (item.quantity == 0 && value == -1) {
      return;
    }
    let body = {
      productId: item.productID,
      quantity: Number(item.quantity) + value!,
    };
    if (value == null) {
      body.quantity = 0;
    }
    this.cartService.updateCart(JSON.stringify(body)).subscribe({
      next: (res: any) => {
        item.quantity = body.quantity;
        this.commonService.setCart(res);
        if (item.quantity == 0) {
          this.commonService.setCartItem(item);
        }
        this.toastService.success('Updated!');
      },
      error: (error) => {
        item.quantity = 0;
        console.log(error);
        this.toastService.warning('Failed!');
      },
    });
  }
}
