import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'fitler',
    standalone: true,
})
export class FilterPipe implements PipeTransform {
    transform(items: any[], filter: any): any {
        if (!items || !filter) {
            return items;
        }

        for (const key in filter) {

            items = items.filter(i => {
                return Object.entries(i).some((v) => v[0] === key && v[1] === filter[key]);
            });
        }

        // filter items array, items which match and return true will be
        // kept, false will be filtered outitems
        return items;
    }
}