import { Component, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
// import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { SharedService } from "@/app/shared/services/shared.service";
import { PermissionAction } from "../../interfaces/role.interface";
// import { AccountUser } from "../../interface/account.interface";
// import { AccountState } from '../../state/account.state';
// import { NotificationState } from '../../state/notification.state';
// import { NavService } from "../../services/nav.service";
// import { Notification } from "../../interface/notification.interface";
// import { SettingState } from "../../state/setting.state";
// import { Values, Language } from "../../interface/setting.interface";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent {

  // @Select(AccountState.user) user$: Observable<AccountUser>;
  // @Select(SettingState.setting) setting$: Observable<Values>;
  // @Select(NotificationState.notification) notification$: Observable<Notification[]>;
  public PermissionAction = PermissionAction;
  public unreadNotificationCount: number | undefined;

  public active: boolean = false;
  public profileOpen: boolean = false;
  public open: boolean = false;

  public languages: any[] = [
    {
      language: 'English',
      code: 'en',
      icon: 'us'
    },
    {
      language: 'Français',
      code: 'fr',
      icon: 'fr'
    },
  ];

  public selectedLanguage: any = {
    language: 'English',
    code: 'en',
    icon: 'us'
  }

  public setting: any;

  constructor(@Inject(DOCUMENT) private document: Document,
  public sharedServices: SharedService ) {
    // this.notification$.subscribe((notification) => {
    //   this.unreadNotificationCount = notification?.filter(item => !item.read_at)?.length;
    // });
    // this.setting$.subscribe(setting => {
    //   document.body.classList.add(setting?.general?.mode!);
    // })
  }

  sidebarToggle() {
    this.sharedServices.collapseSidebar = !this.sharedServices.collapseSidebar;
  }

  clickHeaderOnMobile(){
    this.sharedServices.search = true;
  }

}
