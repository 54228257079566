import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@/environments/environment";
import { BehaviorSubject, Observable } from "rxjs";
import { ProductModel } from "../interfaces/product.interface";

@Injectable({
  providedIn: "root",
})
export class GiftService {

    private updateGiftPointHistory = new BehaviorSubject<boolean>(false);
    reloadGiftPointHistory$ = this.updateGiftPointHistory.asObservable();
    public setUpdateGiftPointHistory(value: boolean) {
        this.updateGiftPointHistory.next(value);
    }

    constructor(
        private http: HttpClient
    ) {}

    getGiftList(body: string) {
        return this.http.post<ProductModel>(`${environment.baseURL}retrieve-products-gift`, body, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
    }

    getGiftHistoryList(body: string) {
        return this.http.post(`${environment.baseURL}user/all-user-point`, body, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
    }

    getGiftDescription(body: string) {
        return this.http.post(`${environment.baseURL}retrieve-products-member`, body, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
    }

    getTotalGiftPoint(userName: string) {
        return this.http.get(`${environment.baseURL}user/point-user/${userName}`);
    }

    updateGiftPoint(body: string) {
        return this.http.post(`${environment.baseURL}user/point-update-admin-by-user`, body, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
    }

}
